import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';


const headerColor = '#719eb8';

const EmuPage = ({ data }) => (
  <>
    <SEO
      title="Top Neosurf Online Casino Australia – Emu Casino"
      description="Join Emu Casino – one of the top-rated Neosurf Australian Casinos. Play over 900 pokies and find out more about exclusive Bonus Offers."
      alternateLanguagePages={[
        {
          path: 'casino/emu',
          language: 'en'
        },
        {
          path: 'casino/emu',
          language: 'fr'
        },
        {
          path: 'casino/emu',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Emu Casino – Online Casino Australia accepting Neosurf" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorDark">Emu Casino – AUS Friendly Neosurf Casino</h1>
            <p className="textColorLight">Emu Casino is one of the top rated Australian online casinos that accept Neosurf deposit method Over a thousand of games from world's best Game Providers, including <strong>over 900 pokies</strong>, amazing selection of Promotions and helpful round the clock Support ensure Emu Casino will provide hours and hours of ultimate gaming experience! Variety of payment options and unique Casino Races just add up to the offer of this amazing Casino! Join today and claim your <strong>Special NEOSURF Welcome Bonus</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Emu Casino's Welcome Bonus</h2>
            <p>Take advantage of Emu Casino’s ‘Triple Welcome Bonus’</p>
            <ul className="textColorDark">
              <li>First deposit – 100% match up to 100$ in bonus cash</li>
              <li>Second deposit- 25% match up to 100$ in bonus cash</li>
              <li>Third deposit – 50% match up to 100$ in bonus cash</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">TO EMU CASINO</a>
            </p>
            <p>Simply go to cashier, select your deposit method and follow instructions to enjoy this amazing Welcome Package deal!</p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Top rated Neosurf Casino Bonus" />
        </div>
      </div>
    </div>
  </>
)

export default EmuPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/emu/neosurf_emucasino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/emu/neosurf_emucasino_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
